import { R } from '../Resources.resx';

export class Texts {
    public static get(
        key: keyof typeof R,
        replacements: { [replacementKey: string]: string } = {},
    ): string {
        return this.getResource(key, replacements);
    }

    public static getResource(
        key: keyof typeof R,
        replacements: { [replacementKey: string]: string } = {},
    ): string {
        const resource = R?.[key]?.replace(
            /\{([^}]+)\}/g,
            function (match, replacementKey) {
                return replacements[replacementKey] ?? match;
            },
        );
        return resource || '';
    }
}
